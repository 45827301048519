const aboutENContent = [
	{
		title: 'Mission',
		text: 'Our mission is to contribute to solving the complex challenges and problems faced by companies in the technologically and socially changing world. From an early age, we are visionaries who had the will and vision to make the world more straightforward and convenient to an average person. <br><br>We view the future as an ocean of opportunities that have never existed before.',
		image: require('@/assets/images/about/02_misja.png')
	},
	{
		title: 'Motivation',
		text: 'We give meaning to everything we do by thoroughly understanding the essence of the problem based on accurate data and a holistic approach. We break classic structures and standard solutions by analysing, experimenting, and delivering a finished product, which - in the end - affects your organisation, people, and environment. <br><br>We follow the principle that the lack of will to face changes will lead the organisation to stagnate, thus conceding ground for organisations that have found the courage to evolve.',
		image: require('@/assets/images/about/03_motywacja.png')
	},
	{
		title: 'Goals',
		text: 'Our ultimate goal is to create solutions that affect the world - your own, as well as those local and global. We want to make a product or service more accessible to reach people who need it, want to get to know it, and ultimately use it. We combine business needs and expectations with our ambitions and experience, creating tools to trigger positive changes. <br><br>We strongly believe that we can build a better world through knowledge and technology, keeping the balance between the digital and real world.',
		image: require('@/assets/images/about/04_cele.png')
	},
]

export {aboutENContent}
