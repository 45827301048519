<template>
	<section class="mission">
		<div class="mission__content">
			<div
				v-for="item in handleContent"
				:key="item.id"
				class="mission__row"
			>
				<div class="mission__text">
					<h2 class="h2-style h2-sm-style">{{ item.title }}</h2>
					<p v-html="item.text"/>
				</div>

				<div class="mission__image">
					<img :src="item.image" alt="illustration">
				</div>
			</div>

			<div class="mission__placeholder">
				<p>
					{{ this.$store.state.isEn ? 'We strongly believe that we can build a better world through knowledge and technology, keeping the balance between the digital and real world.' : 'Głęboko wierzymy, że poprzez wiedzę oraz technologię jesteśmy w stanie budować lepszy świat, zachowując jednocześnie balans pomiędzy światem cyfrowym a rzeczywistym.' }}
				</p>
			</div>

			<div class="check__process">
				<h2>{{ this.$store.state.isEn ? 'Check how we do our projects' : 'Zobacz, w jaki sposób realizujemy projekty' }}  </h2>

				<div class="check__process-buttons">
					<Button
						v-if="!this.$store.state.isEn"
						buttonStyle="primary"
						:additionalElement="true"
						additionalElementStyle="arrowInCircle"
						:whiteArrow="false"
						buttonText="Etapy tworzenia produktu cyfrowego"
						buttonLink="/service-design-proces"
					></Button>
					<Button
						buttonStyle="primary"
						:additionalElement="true"
						additionalElementStyle="arrowInCircle"
						:whiteArrow="false"
						:buttonText="this.$store.state.isEn ? 'Animation Production Process' : 'Proces produkcji animacji'"
						:buttonLink="this.$store.state.isEn ? '/en/animation-process' : '/animacja-proces'"
					></Button>
				</div>
			</div>
			<FAQComponent :currentButton="this.$store.state.isEn ? 'animation' : 'animacja'" />
		</div>
	</section>
</template>

<script>
import {aboutContent} from "@/data/about";
import {aboutENContent} from "@/data/en/mission";
import FAQComponent from '@/components/FAQComponent/index'
export default {
	name: "index about",
	components: {
		FAQComponent
	},
	data() {
		return {
			aboutContent,
			aboutENContent
		}
	},
	computed: {
		handleContent () {
			return this.$store.state.isEn ? this.aboutENContent : this.aboutContent
		}
	}
}
</script>

<style scoped lang="sass" src="./style.sass" />
