const aboutContent = [
	{
		title: 'Misja',
		text: 'Naszą misją jest przyczynianie się do rozwiązywania złożonych wyzwań i problemów, przed którymi stają firmy w obliczu szybko zmieniającego się technologicznie i społecznie świata. Jesteśmy wizjonerami, którzy od najmłodszych lat mieli wolę i wizję uczynienia świata prostszym  i przystępniejszym dla przeciętnego człowieka. <br><br>Traktujemy przyszłość jako ocean nadchodzących możliwości, jakie nie były dostępne nigdy wcześniej.',
		image: require('@/assets/images/about/02_misja.png')
	},
	{
		title: 'Motywacja',
		text: 'Wszystkiemu, co robimy, nadajemy sens poprzez dogłębne zroumienie istoty problemu na podstawie realnych danych i holistycznego podejścia. Przełamujemy klasyczne struktury i standardowe rozwiązania, analizując, eksperymentując i dostarczając na sam koniec gotowy produkt, który wpływa na Twoją organizację, ludzi i otoczenie. <br><br>Kierujemy się zasadą, że brak woli do stawienia czoła zmianom zaprowadzi organizację do stagnacji, a tym samym ustąpienia pola organizacjom, które znalazły w sobie odwagę do ewolucji i podołały zadaniu.',
		image: require('@/assets/images/about/03_motywacja.png')
	},
	{
		title: 'Cele',
		text: 'Naszym nadrzędnym celem jest tworzenie rozwiązań, które zmieniają świat. Twój, lokalny i globalny. Chcemy umożliwić łatwiejsze dotarcie produktu lub usługi ludziom, którzy ich potrzebują, chcą je poznać i z nich skorzystać. Łączymy potrzeby i oczekiwania biznesowe z naszymi ambicjami i doświadczeniem, tworząc narzędzia, które wywołają pozytywne zmiany.',
		image: require('@/assets/images/about/04_cele.png')
	},
]

export {aboutContent}
